import { shortCurrency, longCurrency } from "@/domain/services/CurrencyFormatter"

export const POSITION_GOALKEEPER = 'goalkeeper'
export const POSITION_DEFENSE = 'defenses'
export const POSITION_CENTERS = 'centers'
export const POSITION_STRIKERS = 'strikers'
export default class Player {
  constructor (data) {
    this.aw = data.aw
    this.category = data.category
    this.club = data.club
    this.def = data.def
    this.division = data.division
    this.dri = data.dri
    this.dw = data.dw
    this.id = data.id
    this.name = data.name
    this.nationality = data.nationality
    this.objective = data.objective
    this.pac = data.pac
    this.pas = data.pas
    this.phy = data.phy
    this.position = data.position
    this.canonicalPosition = data.canonicalPosition
    this.rtg = data.rtg
    this.salary = data.salary
    this.sho = data.sho
    this.skill = data.skill
    this.terminationClause = data.terminationClause
    this.reimbursementAfterSell = data.reimbursementAfterSell
    this.wf = data.wf
  }

  get getAW () {
    return this.aw
  }

  get getCategory () {
    return this.category
  }

  get getClub () {
    return this.club
  }

  get getDEF () {
    return this.def
  }

  get getDivision () {
    return this.division
  }

  get getDRI () {
    return this.dri
  }

  get getDW () {
    return this.dw
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getInitials () {
    return this.getName.split(' ').slice(0, 3).map(word => word[0]).join('')
  }

  get getNationality () {
    return this.nationality
  }

  get getObjective () {
    return this.objective
  }

  get getPAC () {
    return this.pac
  }

  get getPAS () {
    return this.pas
  }

  get getPHY () {
    return this.phy
  }

  get getPosition () {
    return this.position
  }

  get getCanonicalPosition () {
    return this.canonicalPosition
  }

  get getRTG () {
    return this.rtg
  }

  get getSalary () {
    return this.salary
  }

  get getFormattedSalary () {
    return shortCurrency(this.salary, 1)
  }

  get getSHO () {
    return this.sho
  }

  get getSkill () {
    return this.skill
  }

  get getTerminationClause () {
    return this.terminationClause
  }

  get getReimbursementAfterSell () {
    return this.reimbursementAfterSell
  }

  get getFormattedReimbursementAfterSellLong () {
    return longCurrency(this.reimbursementAfterSell)
  }

  get getFormattedTerminationClause () {
    return shortCurrency(this.terminationClause, 1)
  }

  get getFormattedTerminationClauseLong () {
    return longCurrency(this.terminationClause)
  }

  get getWF () {
    return this.wf
  }
}

import { daysFromNow } from "../services/DateFormatter"
import { longCurrency } from '../services/CurrencyFormatter'

export const LEAGUE_BUDGET = 150000000;

export default class League {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.active = data.active
    this.code = data.code
    this.maxPlayers = data.maxPlayers
    this.budget = data.budget
    this.salaryTable = data.salaryTable
    this.duration = data.duration
    this.teamMaxPlayers = data.teamMaxPlayers
    this.season = data.season,
    this.createdUser = data.createdUser
}

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get isActive () {
    return this.active
  }

  get getCode () {
    return this.code
  }

  get getMaxPlayers () {
    return this.maxPlayers
  }

  get getBudget () {
    return this.budget
  }

  get getSalaryTable () {
    return this.budget
  }

  get getDuration () {
    return this.duration
  }

  get getFormattedSalaryTableLong () {
    return longCurrency(this.salaryTable)
  }

  get getTeamMaxPlayers () {
    return this.teamMaxPlayers
  }

  get getSeason () {
    return this.season
  }

  get getCreatedUser () {
    return this.createdUser
  }

  get getTimeRemaining () {
    const diff = this.getSeason.getRegularStartAt.getTime() + 86400000 * this.getDuration
    const { days, hours, mins } = daysFromNow(diff)
    return `${days}d ${hours}h ${mins}m`
  }
}

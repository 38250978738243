<template>
  <ion-page class="ion-page">
    <ion-content scroll-x>
      <div class="tabMarket">
        <div class="tabMarket__in-draft" v-if="isDraft">
          <InDraft
            :remaining="draftRemaining"
          />
        </div>
        <div v-if="isBids">
          <MenuHeader
            showMenuButton
          >
            <div class="ion-text-end tabMarket__title">Pujas <img src="/assets/info.svg" width="20" @click="openBidInfo" /></div>
            <template v-slot:info>
              <div class="ion-text-end">Ronda {{ bidBatch.getCurrentDay }} de 6 <img src="/assets/fire.png" width="16"></div>
            </template>
          </MenuHeader>
          <BidCard
            :key="season"
            :league="league"
            :season="season"
            :team="team"
            @bidded="updateBudget"
          />
        </div>
        <div v-if="isOpenMarket">
          <MenuHeader
            showMenuButton
          >
            <div class="ion-text-end tabMarket__title">Mercado<img src="/assets/info.svg" width="20" @click="openOpenMarketInfo" /></div>
            <template v-slot:info>
              <div class="ion-text-end">Mercado abierto <img src="/assets/fire.png" width="16"></div>
            </template>
          </MenuHeader>
          <OpenMarketCard
            :key="season"
            :league="league"
            :season="season"
            :team="team"
          />
        </div>
        <div v-if="isRegular">
          <RegularCard />
        </div>
      </div>
      <PopupModal
        :is-open="openBidInfoModal"
        @closed="hideBidInfoModal"
      >
        <div class="popupModal__standard">
          <div>
            Piensa sabiamente que posiciones quieres reforzar y estate atento a los
            jugadores que más pujas hayan recibido para establecer con más precisión tu puja.
            Solo hay 6 rondas, que no se te escapen tus jugadores favoritos.
          </div>
          <div>
            <SmallButton text="Cerrar" @onClick="hideBidInfoModal" />
          </div>
        </div>
      </PopupModal>
      <PopupModal
        :is-open="openOpenMarketInfoModal"
        @closed="hideOpenMarketInfoModal"
      >
        <div class="popupModal__standard">
          <div>
            Tú última oportunidad para definir tu equipo y ajustar al máximo tu masa salarial!
            Recuerda que en caso de no cumplir con el requisito de tener 18 jugadores o
            pasarte de masa salarial, la aplicación actuará de forma automática sobre tu
            plantilla y lo ajustará, te arriesgas a perder a tus jugadores favoritos!
          </div>
          <div>
            <SmallButton text="Cerrar" @onClick="hideOpenMarketInfoModal" />
          </div>
        </div>
      </PopupModal>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, onIonViewWillEnter } from '@ionic/vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, ref } from '@vue/runtime-core'
import {
  STATUS_DRAFT as LEAGUE_STATUS_DRAFT,
  STATUS_BIDS as LEAGUE_STATUS_BIDS,
  STATUS_OPEN_MARKET as LEAGUE_STATUS_OPEN_MARKET,
  STATUS_REGULAR as LEAGUE_STATUS_REGULAR,
} from '@/domain/models/Season'
import BidCard from './Market/BidCard.vue'
import OpenMarketCard from './Market/OpenMarketCard.vue'
import RegularCard from './Market/RegularCard.vue'
import InDraft from '@/components/LeagueStatusCard/InDraft.vue'
import MenuHeader from '@/components/Menu/MenuHeader.vue'
import PopupModal from '../../components/PopupModal/PopupModal.vue'
import SmallButton from '@/components/Button/SmallButton.vue'

export default  {
  name: 'TabMarket',
  components: {
    IonContent,
    IonPage,
    BidCard,
    OpenMarketCard,
    RegularCard,
    InDraft,
    MenuHeader,
    PopupModal,
    SmallButton
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const league = computed(() => store.getters.getCurrentLeague)
    const season = computed(() => store.getters.getCurrentLeague.getSeason)
    const team = computed(() => store.getters.getCurrentTeam)
    const bidBatch = ref({})
    const openBidInfoModal = ref(false)
    const openOpenMarketInfoModal = ref(false)

    const isDraft = computed(() => season.value.getStatus == LEAGUE_STATUS_DRAFT)
    const isBids = computed(() => season.value.getStatus == LEAGUE_STATUS_BIDS)
    const isOpenMarket = computed(() => season.value.getStatus == LEAGUE_STATUS_OPEN_MARKET)
    const isRegular = computed(() => season.value.getStatus == LEAGUE_STATUS_REGULAR)
    const draftRemaining = computed(() => season.value.getBidStartAt)

    if (isBids.value) {
      const data = {
        leagueId: league.value.getId,
        seasonId: season.value.getId,
      }
      store.dispatch('getBidBatch', data)
        .then(res => {
          bidBatch.value = res
        })
    }

    onIonViewWillEnter(() => {
      if (isDraft.value && team.value.getTeamPlayers.length == 0) {
        router.push({ name: 'draft', params: { id: league.value.getId } })
      }
    })

    const refreshTeam = () => {
      store.dispatch('getLeague', league.value.getId)
        .then((currentLeague) => {
          store.commit('setCurrentTeam', {
            league: currentLeague,
            user: store.getters.getLoggedUser
          })
          team.value = store.getters.getCurrentTeam
        })
    }

    const updateBudget = (amount) => {
      team.value.setVirtualBudget(team.value.getVirtualBudget - amount)
    }

    const hideBidInfoModal = () => {
      openBidInfoModal.value = false
    }

    const openBidInfo = () => {
      openBidInfoModal.value = true
    }

    const hideOpenMarketInfoModal = () => {
      openBidInfoModal.value = false
    }

    const openOpenMarketInfo = () => {
      openBidInfoModal.value = true
    }

    return {
      openBidInfoModal,
      openOpenMarketInfoModal,
      isDraft,
      isBids,
      isOpenMarket,
      isRegular,
      draftRemaining,
      league,
      season,
      team,
      bidBatch,

      openBidInfo,
      hideBidInfoModal,
      openOpenMarketInfo,
      hideOpenMarketInfoModal,
      refreshTeam,
      updateBudget,
    }
  }
}
</script>

<style lang="sass" scoped>
 @import 'TabMarket.sass'
</style>

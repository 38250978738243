<template>
  <div class="playerInfoCard">
    <div class="playerInfoCard__left">
      <div class="playerInfoCard__left__initials">
        {{ player.getInitials }}
      </div>
      <div class="playerInfoCard__left__rtg">
        <span class="playerInfoCard__left__rtg__value">{{ player.getRTG }}</span>
        <span class="playerInfoCard__left__rtg__label">Rtg</span>
      </div>
    </div>
    <div class="playerInfoCard__info">
      <div class="playerInfoCard__info__header">
        <div class="top-header" @click="$emit('clicked')">
          <div class="playerInfoCard__info__name">
            {{ player.getName }}
          </div>
          <SmallBadge>{{ player.getPosition }}</SmallBadge>
        </div>
        <slot name="subtitle" />
      </div>
      <div class="playerInfoCard__info__footer">
        <div class="playerInfoCard__info__footer__top">
          {{ player.getClub }}
        </div>
        <div class="playerInfoCard__info__footer__bottom">
          Salario: {{ player.getFormattedSalary }} | Cláusula {{ player.getFormattedTerminationClause }}
        </div>
      </div>
    </div>
    <slot name="right">
      <div class="playerInfoCard__right">
        <div class="playerInfoCard__right__top">
          <slot>
            <div class="playerInfoCard__right__locked" v-if="locked">
              <img src="/assets/lock.svg" height="20">
            </div>
          </slot>
        </div>
        <div class="playerInfoCard__right__obj" :class="player.getObjective">
          {{ player.getObjective }}
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Player from "@/domain/models/Player";
import SmallBadge from '../Badge/SmallBadge.vue';

export default {
  components: { SmallBadge },
  name: "PlayerInfoCard",
  props: {
    player: Player,
    locked: Boolean
  }
}
</script>

<style lang="sass" scoped>
  @import 'PlayerInfoCard.sass'
</style>

<template>
  <ion-grid class="playerGroup">
    <ion-row class="playerGroup__title">
      <ion-col>
        {{ translatedName }}
      </ion-col>
    </ion-row>
    <ion-row class="playerGroup__player" :class="{ 'has-error': !valid }">
      <ion-col>
        <ion-radio-group @ionChange="playerSelected" :value="value">
          <div v-for="(teamPlayer) in players" :key="teamPlayer" @click="$emit('playerClick', teamPlayer)">
            <slot :teamPlayer="teamPlayer">
              <PlayerInfoCard
                :player="teamPlayer.getPlayer"
                :locked="teamPlayer.isLocked"
              >
                <ion-radio v-if="showRadio" mode="ios" slot="start" :value="teamPlayer.getPlayer.getId" @click.stop="" />
              </PlayerInfoCard>
            </slot>
          </div>
        </ion-radio-group>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
} from '@ionic/vue';
import { getPluralMessage } from '@/domain/services/I18NMessages'
import PlayerInfoCard from "@/components/PlayerInfoCard/PlayerInfoCard";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonRadioGroup,
    IonRadio,

    PlayerInfoCard,
  },
  emits: ['changed', 'blur', 'playerClick'],
  props: {
    value: String,
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    valid: {
      type: Boolean,
      default: true
    },
    players: Array,
    showRadio: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const translatedName = getPluralMessage(props.name)

    const playerSelected = ({ detail }) => {
      emit('changed', {
        playerGroupName: props.name,
        id: props.id,
        value: detail.value
      })
    }

    return {
      translatedName,

      playerSelected
    }
  },
}
</script>

<style lang="sass" scoped>
  @import './PlayerGroup.sass'
</style>
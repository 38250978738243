import {
  login,
  refreshToken,
  requestPasswordReset,
  passwordReset
} from '@/api/AuthAPI'
import { getUser, createUser } from '@/api/UserAPI'
import {
  getLeagueByCode,
  createUserLeague,
  getUserLeagues,
  getLeague,
  appendUserToLeague,
} from '@/api/LeagueAPI'
import {
  createSeason,
  getSeasonBestAttack,
  getSeasonBestDefense,
  getSeasonPichichi,
  getSeasonZamora,
  getSeasonMVPs,
  deleteSeasonTeam,
} from '@/api/SeasonAPI'
import { getNews, createNews } from '@/api/NewsAPI'
import { getBidBatch } from '@/api/BidBatchAPI'
import {
  getTeamResults,
  updateResult,
  forceUpdateResult,
  getResult
} from '@/api/ResultAPI'
import {
  createTeam,
  getTeam,
  updateTeam,
  getTeamDraftedPlayers,
  saveTeamPlayers,
} from '@/api/TeamAPI'
import { createScorer, forceCreateScorer } from '@/api/ScorerAPI'
import {
  searchFreeAgents,
  buyPlayer,
  sellPlayer,
} from '@/api/PlayerAPI'
import { getSponsors } from '@/api/SponsorAPI'
import {
  getBotNews,
  getTransactions
} from '@/api/BotNewsAPI'
import { getBids, createBid, updateBid } from '@/api/BidAPI'
import {
  createOffer,
  getMyOffers,
  getOffers,
  deleteOffer,
  deleteMyOffer,
  acceptOffer,
  rejectOffer
} from '@/api/OfferAPI'
import { updateTeamPlayer, buyTeamPlayer } from '@/api/TeamPlayerAPI'

export default {
  login ({ commit }, formData) {
    return login(formData)
      .then(res => {
        commit('setAuth', res.data)
        return res
      })
      .catch(error => Promise.reject(error))
  },
  refreshToken ({ commit, getters }) {
    const refreshTokenString = getters.getRefreshToken
    return refreshToken(refreshTokenString)
      .then(res => {
        commit('setAuth', res.data)
      })
      .catch(error => Promise.reject(error))
  },

  recoveryUserPassword (_, email) {
    return requestPasswordReset(email)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  passwordReset (_, { token, password }) {
    return passwordReset(token, password)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getUser ({ commit }) {
    return getUser()
      .then(res => {
        commit('setUser', res)
        return res
      })
      .catch(error => Promise.reject(error))
  },
  createUser (_, { username, fifaId, email, password, terms }) {
    return createUser(username, fifaId, email, password, terms)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getLeagueByCode (_, code) {
    return getLeagueByCode(code)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  createUserLeague (_, league) {
    return createUserLeague(league)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getUserLeagues () {
    return getUserLeagues()
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getLeague ({ commit, getters }, leagueId) {
    return getLeague(leagueId)
      .then(league => {
        commit('setCurrentLeague', league)
        commit('setCurrentTeam', {
          league: league,
          user: getters.getLoggedUser
        })
        return league
      })
      .catch(error => Promise.reject(error))
  },

  getSeasonBestAttack (_, { leagueId, seasonId }) {
    return getSeasonBestAttack(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getSeasonBestDefense (_, { leagueId, seasonId }) {
    return getSeasonBestDefense(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getSeasonPichichi (_, { leagueId, seasonId }) {
    return getSeasonPichichi(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getSeasonZamora (_, { leagueId, seasonId }) {
    return getSeasonZamora(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getSeasonMVPs (_, { leagueId, seasonId }) {
    return getSeasonMVPs(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  deleteSeasonTeam (_, { leagueId, seasonId, teamId }) {
    return deleteSeasonTeam(leagueId, seasonId, teamId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  createSeason ({ dispatch }, season) {
    return createSeason(season)
      .then(res => {
        dispatch('getLeague', season.leagueId)
        return res
      })
      .catch(error => Promise.reject(error))
  },

  appendUserToLeague (_, code) {
    return appendUserToLeague(code)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getTeamResults (_, { leagueId, seasonId }) {
    return getTeamResults(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  updateResult (_, { leagueId, seasonId, resultId, data }) {
    return updateResult(leagueId, seasonId, resultId, data)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  forceUpdateResult (_, { leagueId, seasonId, resultId, data }) {
    return forceUpdateResult(leagueId, seasonId, resultId, data)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  getResult (_, { leagueId, seasonId, resultId }) {
    return getResult(leagueId, seasonId, resultId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  createTeam ({ dispatch }, { sponsorId, leagueId, seasonId, teamName }) {
    return createTeam(leagueId, seasonId, sponsorId, teamName)
      .then(team => {
        dispatch('getLeague', leagueId)
        return team
      })
      .catch(error => Promise.reject(error))
  },

  getTeam (_, { leagueId, seasonId, teamId }) {
    return getTeam(leagueId, seasonId, teamId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  updateTeam (_, { leagueId, seasonId, teamId, sponsorId }) {
    return updateTeam(leagueId, seasonId, teamId, sponsorId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getTeamDraftedPlayers (_, { leagueId, seasonId, teamId }) {
    return getTeamDraftedPlayers(leagueId, seasonId, teamId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  saveTeamPlayers ({ dispatch }, { leagueId, teamId, players }) {
    return saveTeamPlayers(teamId, players)
      .then(res => {
        dispatch('getLeague', leagueId)
        return res
      })
      .catch(error => Promise.reject(error))
  },

  createScorer (_, { leagueId, seasonId, resultId, data }) {
    return createScorer(leagueId, seasonId, resultId, data)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  forceCreateScorer (_, { leagueId, seasonId, resultId, data }) {
    return forceCreateScorer(leagueId, seasonId, resultId, data)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getSponsors () {
    return getSponsors()
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getBidBatch (_, { leagueId, seasonId }) {
    return getBidBatch(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getBids (_, { leagueId, seasonId, teamId }) {
    return getBids(leagueId, seasonId, teamId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  createBid (_, { leagueId, seasonId, teamId, bidBatchId, bidBatchPlayerId, amount }) {
    return createBid(leagueId, seasonId, teamId, bidBatchId, bidBatchPlayerId, amount)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  updateBid (_, {bidId, leagueId, seasonId, teamId, bidBatchId, bidBatchPlayerId, amount }) {
    return updateBid(bidId, leagueId, seasonId, teamId, bidBatchId, bidBatchPlayerId, amount)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  getNews (_, { leagueId, seasonId }) {
    return getNews(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  createNews (_, {leagueId: leagueId, seasonId: seasonId, message: message }) {
    return createNews(leagueId, seasonId, message)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  getBotNews (_, { leagueId, seasonId }) {
    return getBotNews(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  getTransactions (_, { leagueId, seasonId }) {
    return getTransactions(leagueId, seasonId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  searchFreeAgents (_, { leagueId, seasonId, name, sort }) {
    return searchFreeAgents(leagueId, seasonId, name, sort)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  buyPlayer (_, { leagueId: leagueId, seasonId: seasonId, teamId: teamId, playerId: playerId }) {
    return buyPlayer(leagueId, seasonId, teamId, playerId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  sellPlayer (_, { leagueId: leagueId, seasonId: seasonId, teamId: teamId, playerId: playerId }) {
    return sellPlayer(leagueId, seasonId, teamId, playerId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  refreshTeam ({ getters, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      const league = getters.getCurrentLeague
      dispatch('getLeague', league.getId)
      .then(league => {
        commit('setCurrentTeam', {
          league: league,
          user: getters.getLoggedUser
        })
        resolve(getters.getCurrentTeam)
      })
      .catch(error => reject(error))
    })
  },

  createOffer (_, { leagueId, seasonId, fromTeamId, toTeamId, playerId, amount }) {
    return createOffer(leagueId, seasonId, fromTeamId, toTeamId, playerId, amount)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  getMyOffers (_, { leagueId, seasonId, teamId }) {
    return getMyOffers(leagueId, seasonId, teamId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  getOffers (_, { leagueId, seasonId, teamId }) {
    return getOffers(leagueId, seasonId, teamId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  deleteOffer (_, { leagueId, seasonId, teamId, offerId }) {
    return deleteOffer(leagueId, seasonId, teamId, offerId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  deleteMyOffer (_, { leagueId, seasonId, teamId, offerId }) {
    return deleteMyOffer(leagueId, seasonId, teamId, offerId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  acceptOffer (_, { leagueId, seasonId, teamId, offerId }) {
    return acceptOffer(leagueId, seasonId, teamId, offerId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },
  rejectOffer (_, { leagueId, seasonId, teamId, offerId }) {
    return rejectOffer(leagueId, seasonId, teamId, offerId)
      .then(res => res)
      .catch(error => Promise.reject(error))
  },

  updateTeamPlayer (_, { leagueId, seasonId, teamId, teamPlayerId, locked }) {
    return updateTeamPlayer(leagueId, seasonId, teamId, teamPlayerId, locked)
    .then(res => res)
    .catch(error => Promise.reject(error))
  },
  buyTeamPlayer (_, { leagueId, seasonId, teamId, teamPlayerId }) {
    return buyTeamPlayer(leagueId, seasonId, teamId, teamPlayerId)
    .then(res => res)
    .catch(error => Promise.reject(error))
  }
}
